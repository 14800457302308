@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800&display=swap");

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
}

.list-container{
  max-height: calc(100vh - 120px);
  min-width: 340px;
}
.list-container .list-inner-container{
  max-height: calc(100vh - 165px);
}

.pdf-thumnail-container canvas{
  max-height: 70px;
  max-width: 80px;
  border-radius: 0.25rem;
  margin: auto;
  width: auto !important;
}
.pdf-thumnail-container.big canvas{
  max-height: 140px;
  max-width: 160px;
}
.pdf-thumnail-container [class*="react-pdf"] > [class*="--loading"]{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
}
.pdf-thumnail-container .react-pdf__Page__textContent,
.pdf-thumnail-container .react-pdf__Page__annotations{
  display: none;
}
.pdf-viewer canvas{
  max-width: 100% !important;
  max-height: calc(100vh - 10px) !important;
  height: 100% !important;
  margin: auto;
}
.pdf-viewer.max-h-screen{
  margin: auto;
  overflow: hidden;
  min-height: calc(100vh - 50px) !important;
}
.pdf-viewer.max-h-screen > [class*="react-pdf"] > [class*="--loading"]{
  min-width: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 50px) !important;
}
.pdf-viewer.max-h-screen > [class*="react-pdf"] > [class*="--error"]{
  padding: 20px
}
.pdf-viewer.max-h-screen canvas{
  height: auto !important;
  width: auto !important;
}

.pdf-page-control{
  bottom: 2%;
  white-space: nowrap;
  transition: opacity ease-in-out 0.2s;
  box-shadow: 0 30px 40px 0 rgb(16 36 94 / 20%);
}
.pdf-page-control button{
  width: 44px;
  height: 44px;
}
.pdf-page-control button:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.pdf-page-control button:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.pdf-page-control button:hover, .pdf-page-control button:focus {
  background-color: #e6e6e6;
}
.pdf-page-control button:disabled{
  opacity: 0.5;
  background: #ffffff;
}

.swiper .swiper-wrapper{
  align-items: center;
}
.swiper .swiper-button-prev{
  left: 20px;
}
.swiper .swiper-button-next{
  right: 20px;
}
.swiper .swiper-button-prev:after, .swiper .swiper-button-next:after{
  font-size: 25px;
  color: rgb(203 246 254 / var(--tw-bg-opacity));
}

.dotted-background{
  background: linear-gradient(
    90deg, #F2EEF6 9px, transparent 1%
  ) center, linear-gradient(
    #F2EEF6 9px, transparent 1%
  ) center, #a799cc;
  background-size: 10px 10px;
}
.thumbnail{
  max-height: 70px;
  position: relative;
}
.thumbnail.big{
  max-height: 140px
}
.thumbnail.selected:after{
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #7364B4;
  z-index: 1;
  opacity: .5;
  border-radius: 0.25rem;
}
.min-350-container{
  min-width: 350px;
}

textarea.textfield{
  min-height: 100px;
  line-height: 1.25;
}

.custom-input{
  min-height: 16px;
  display: flex;
  align-items: center;
}
.custom-input input + .checkmark{
  height: 16px;
  width: 16px;
  cursor: pointer;
  border-radius: 3px;
}
.custom-input input ~ .label-text{
  @apply cursor-pointer text-text-gray;
}
.custom-input input[type="radio"] + .checkmark{
  border-radius: 50%;
}
.custom-input input[type="checkbox"] + .checkmark:after{
  content: "";
  position: absolute;
  opacity: 0;
  visibility: hidden;
  left: 5px;
  top: 2px;
  width: 4px;
  height: 9px;
  border: 1px solid #7364B4;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  transition: opacity .2s ease 0s;
}
.custom-input input[type="radio"] + .checkmark:after{
  content: "";
  position: absolute;
  opacity: 0;
  visibility: hidden;
  left: 3px;
  top: 3px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #7364B4;
  transition: opacity .2s ease 0s;
}
.custom-input input:checked + .checkmark{
  background: rgba(115, 100, 180, 0.2);
}
.custom-input input:checked ~ .label-text{
  @apply cursor-pointer text-black;
}
.custom-input input:checked + .checkmark:after{
  opacity: 1;
  visibility: visible;
  transition: opacity .2s ease 0s;
}
.custom-input input[disabled] + .checkmark,
.custom-input input[disabled] ~ .label-text{
  opacity: 0.5;
  cursor: not-allowed;
}
/* .circle-wrap .mask {
  clip: rect(0px, 110px, 110px, 75px);
}
.circle-wrap .mask.full, .circle-wrap .mask .fill {
  animation: fill ease-in-out 3s;
}
.circle-wrap .mask .fill{
  clip: rect(0px, 55px, 110px, 0px);
} */

.circle-wrap {
  margin: 0 auto;
  width: 150px;
  height: 150px;
  border-radius: 50%;
}
.circle-wrap .circle .mask, .circle-wrap .circle .fill {
  width: 150px;
  height: 150px;
  position: absolute;
  border-radius: 50%;
}
.circle-wrap .circle .mask {
  clip: rect(0px, 150px, 150px, 75px);
}
.circle-wrap .circle .mask.full, .circle-wrap .circle .fill {
  animation: fill ease-in-out 3s;
  transform: rotate(165deg);
}
.circle-wrap .circle .mask .fill {
  clip: rect(0px, 75px, 150px, 0px);
}

.top > .row:last-child:after, .bottom > .row:after{
  content: "";
  position: absolute;
  top: 20px;
  height: 2px;
  background-color: #929497;
  width: 75%;
  left: 12px
}
.top > .row:first-child .block:first-child h5{
  padding-left: 15px
}
.top > .row:first-child .block:first-child{
  width: 300px;
  display: flex;
  align-items: center;
  padding: 0;
}
.top > .row:first-child .block:first-child:after,
.top > .row:last-child > .block:first-child:after,
.top > .row:last-child > .block:last-child:after,
.bottom > .row:first-child > .block:first-child:after,
.bottom > .row:first-child > .block:last-child:after,
.bottom > .row:last-child > .block:first-child:after{
  content: "";
  position: absolute;
  height: 74px;
  border: 2px solid #929497;
  border-radius: 15px;
}
.top > .row:first-child .block:first-child:after{
  top: calc(50% - 2px);
  width: 50px;
  left: 100%;
  border-left-width: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.top > .row:last-child > .block:last-child:after,
.bottom > .row:first-child > .block:last-child:after{
  top: 55%;
  width: 50px;
  border-top-width: 0;
  border-left-width: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.top > .row:last-child > .block:first-child:after,
.bottom > .row:first-child > .block:first-child:after,
.bottom > .row:last-child > .block:first-child:after{
  top: -30px;
  width: 12px;
  left: 0;
  border-right-width: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.top > .row:last-child > .block:last-child:after{
  left: 5px;
  height: 136px;
  z-index: 100;
  top: calc(50% + 11px);
}

.bottom > .row:first-child:after{
  top: 0;
}
.bottom > .row:last-child:after{
  width: 40%;
  left: calc(39% + 3px);
}
.bottom > .row:first-child > .block:first-child:after{
  top: -50px
}
.bottom > .row:first-child > .block:last-child:after{
  height: 71px;
  width: 30px;
  top: 65%;
}
.bottom > .row:last-child > .block:first-child:after{
  height: 50px;
}
@media (min-width: 300px) and (max-width: 1024px){
  .top > .row:first-child .block:first-child{
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
  }
  .top > .row:first-child .block:first-child > h5{
    width: 100%;
    margin-top: 45px;
    padding-left: 45px;
  }
  .top > .row:last-child{
    padding-top: 15px;
  }
  .top > .row:first-child .block:first-child:after{
    top: calc(50% + 2px);
    left: calc(50% - 50px);
    border-top-width: 0;
    border-top-right-radius: 0;
    height: 30px;
  }
  .top > .row:last-child > .block:first-child:after{
    top: -73px;
    left: 20px;
    width: calc(50% - 40px);
    height: 10px;
    border-bottom: 0;
    border-bottom-left-radius: 0;
  }
  .bottom > .row:after, 
  .top > .row:last-child:after, 
  .bottom > .row:first-child > .block:last-child:after,
  .bottom > .row:last-child > .block:first-child:after{
    display: none;
  }
  .top > .row > .block > h5, .bottom > .row > .block > h5{
    position: relative;
  }
  .top > .row > .block > h5:after, .bottom > .row > .block > h5:after{
    position: absolute;
    content: "";
    width: 2px;
    left: -25px;
    top: -15px;
    bottom: 0;
    border-left: 2px solid #929497;
  }
  .top > .row:first-child > .block:first-child > h5:after{
    left: 20px;
    top: -10px;
    border-radius: 15px;
  }
  .top > .row:last-child > .block:first-child > h5:after{
    bottom: -20px
  }
  .top > .row:last-child > .block:last-child:after, 
  .bottom > .row:first-child > .block:first-child:after{
    width: 0;
  }
  .top > .row:last-child > .block:last-child:after, 
  .bottom > .row:first-child > .block:first-child:after{
    left: 20px;
  }
  .bottom > .row:first-child > .block:first-child:after{
    top: -60px;
    height: 45px;
  }
  .bottom > .row:last-child > .block:last-child > h5:after{
    bottom: 10px
  }
  .top > .row > .block > h5:before, .bottom > .row > .block > h5:before{
    content: "";
    width: 10px;
    height: 10px;
    background-color: #929497;
    border-radius: 50%;
    position: absolute;
    left: -29px;
    top: calc(50% - 5px);
  }
  .top > .row:first-child > .block:first-child > h5:before{
    left: 16px;
  }
}
@media (min-width: 819px) and (max-width: 1024px){
  .top > .row:last-child > .block:first-child:after{
    top: -64px;
    height: 20px
  }
  .top, .bottom{
    padding: 0 120px
  }
}

.box-shadow{
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 15%);
  transition: all 0.5s ease-in-out;
}
.box-shadow:hover{
  box-shadow: 0 4px 5px -2px rgb(0 0 0 / 20%), 0 7px 10px 1px rgb(0 0 0 / 14%), 0 2px 16px 1px rgb(0 0 0 / 12%);
}
.footer-shadow{
  box-shadow: 0 -2px 4px 0 rgb(0 0 0 / 15%);
}
.primary-btn {
  @apply border rounded-md px-6 py-2 bg-gradient-to-r from-white-purple to-deep-purple 
  text-white font-medium text-xs focus:bg-purple disabled:opacity-50 disabled:cursor-not-allowed;
}
.secondary-btn {
  @apply border rounded-md px-6 py-2 border-purple-light text-purple-light font-medium
  text-xs disabled:opacity-50 disabled:cursor-not-allowed;
}
.btn-danger{
  @apply border rounded-md px-6 py-2 bg-pink text-white font-medium 
  text-xs focus:bg-pink-deep disabled:opacity-50 disabled:cursor-not-allowed;
}

.custom-form ::-webkit-input-placeholder {
  /* WebKit browsers */
   color: transparent;
}
.custom-form :-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
   color: transparent;
}
.custom-form ::-moz-placeholder {
  /* Mozilla Firefox 19+ */
   color: transparent;
}
.custom-form :-ms-input-placeholder {
  /* Internet Explorer 10+ */
   color: transparent;
}
.custom-form input::placeholder {
   color: transparent;
}
.custom-form textarea::-webkit-input-placeholder {
  /* WebKit browsers */
   color: transparent;
}
.custom-form textarea:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
   color: transparent;
}
.custom-form textarea::-moz-placeholder {
  /* Mozilla Firefox 19+ */
   color: transparent;
}
.custom-form textarea:-ms-input-placeholder {
  /* Internet Explorer 10+ */
   color: transparent;
}
.custom-form textarea::placeholder {
   color: transparent;
}
.custom-form hr{
  @apply border-gray;
}
.custom-form h3:first-child{
  margin-top: 0 !important;
}
.custom-form label.form-label{
  @apply flex text-text-gray text-xs whitespace-nowrap overflow-hidden text-ellipsis tracking-wide;
}
.custom-form .input-group {
  @apply h-9 w-60 pt-3;
}
.custom-form .input-group [readonly]{
  opacity: 0.6;
  user-select: none;
}
.custom-form .input-group.full{
  @apply w-full;
}
.custom-form .input-group.textarea {
  @apply h-23 w-full;
}
.custom-form .input-group label{
  transform: translate(3px, -22px);
  @apply absolute text-base transition-all h-3 block duration-200 ease-out;
}
.custom-form .input-group input,
.custom-form .input-group textarea{
  @apply w-full h-6 p-1 pl-0.5 pr-2 border-b border-gray transition-all duration-200 
  ease-out outline-none focus:border-sky tracking-wide;
}
.custom-form .input-group textarea{
  @apply h-20 resize-none leading-tight;
}
.custom-form .input-group textarea + label{
  transform: translate(3px, -95px);
}
.custom-form .input-group input.has-error{
  @apply border-pink-deep;
}
.custom-form .input-group input:focus + label, 
.custom-form .input-group input.has-value + label,
.custom-form .input-group.focused label,
.custom-form .input-group.has-value label{
  transform: translate(3px, -42px);
  @apply text-xs;
}
.custom-form .input-group textarea:focus + label,
.custom-form .input-group textarea.has-value + label{
  transform: translate(3px, -115px);
  @apply text-xs;
}

.custom-dropdown{
  @apply pt-3 -mt-px;
}
.custom-dropdown [class$="-menu"]{
  @apply m-0 mt-0.5 rounded;
}
.custom-dropdown [class$="-menu"] [class$="-MenuList"]{
  @apply p-0;
}
.custom-dropdown [class$="-control"]{
  @apply border-0 border-b border-gray rounded-none min-h-auto shadow-none focus:border-sky hover:border-sky;
}
.custom-dropdown [class$="-control"] > div:first-child{
  @apply p-0 h-6;
}
.custom-dropdown [class$="-control"] > div:first-child > [class$="-singleValue"]{
  @apply h-6 flex items-center ml-0.5;
}
.custom-dropdown [class$="-control"] > div:first-child > [class$="-Input"]{
  @apply ml-0.5 m-0 p-0;
}
.custom-dropdown [class$="-control"] > div:first-child > [class$="-placeholder"]{
  @apply ml-0.5 mr-0 relative flex;
}
.custom-dropdown [class$="-control"] > div:last-child > [class$="-indicatorSeparator"]{
  @apply hidden;
}
.custom-dropdown [class$="-control"] > div:last-child > [class$="-indicatorContainer"]{
  @apply pt-1 pb-0.8 pr-0.5;
}
.custom-dropdown.touched.invalid [class$="-control"]{
  @apply border-pink-deep;
}
.custom-dropdown.required [class$="-control"] > div:first-child > [class$="-placeholder"]:after{
  content: "*";
  @apply text-pink-deep ml-px;
}
.custom-form .custom-dropdown + label.form-label{
  @apply absolute transition-all duration-200 ease-out top-0.5 left-0.5;
}
[class$="-menu"] > div > .css-9gakcf-option{
  @apply bg-purple-l;
}
[class$="-menu"] > div > .css-1n7v3ny-option, [class$="-menu"] > div > .css-1n7v3ny-option:active{
  @apply bg-list-bg;
}

.dropdown-with-bg [class$="-control"]{
  @apply bg-list-bg border-0 min-h-7;
}
.dropdown-with-bg [class$="-control"] > div:last-child > [class$="-indicatorSeparator"]{
  @apply hidden;
}
.dropdown-with-bg [class$="-control"]:hover, .dropdown-with-bg [class$="-control"]:focus{
  @apply border-0 border-transparent;
}
.dropdown-with-bg [class$="-indicatorContainer"]{
  padding: 7px;
}
.dropdown-with-bg [class$="-menu"]{
  @apply mt-1 mb-1;
}
.dropdown-with-bg [class$="-control"] [class$="-ValueContainer"] > [class$="-multiValue"]{
  @apply bg-purple-light;
}
.dropdown-with-bg [class$="-control"] [class$="-ValueContainer"] > [class$="-multiValue"] > *{
  @apply text-white;
}

.react-datepicker-wrapper + .react-datepicker__tab-loop .react-datepicker-popper{
  @apply z-30;
}
.react-datepicker__tab-loop .react-datepicker-popper[data-placement^=bottom]{
  @apply pt-1 pl-0.5;
}
.react-datepicker__tab-loop .react-datepicker-popper[data-placement^=top]{
  @apply pb-1 pl-0.5;
}
.react-datepicker__tab-loop .react-datepicker-popper .react-datepicker{
  font-family: inherit;
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 15%);
  @apply border-none rounded leading-none;
}
.react-datepicker__tab-loop .react-datepicker .react-datepicker__year-read-view--down-arrow, 
.react-datepicker__tab-loop .react-datepicker .react-datepicker__month-read-view--down-arrow, 
.react-datepicker__tab-loop .react-datepicker .react-datepicker__month-year-read-view--down-arrow, 
.react-datepicker__tab-loop .react-datepicker .react-datepicker__navigation-icon::before{
  border-width: 1px 1px 0 0;
  @apply border-sky;
}
.react-datepicker__tab-loop .react-datepicker .react-datepicker__month-container,
.react-datepicker__tab-loop .react-datepicker .react-datepicker__month-container .react-datepicker__header{
  @apply border-none bg-transparent rounded-none;
}
.react-datepicker__tab-loop .react-datepicker .react-datepicker__header .react-datepicker__current-month,
.react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__triangle{
  @apply hidden;
}
.react-datepicker__tab-loop .react-datepicker .react-datepicker__header .react-datepicker__header__dropdown{
  @apply mb-2 mt-0.5 flex justify-center items-center;
}
.react-datepicker__tab-loop .react-datepicker .react-datepicker__header .react-datepicker__month-dropdown,
.react-datepicker__tab-loop .react-datepicker .react-datepicker__header .react-datepicker__year-dropdown{
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 15%);
  @apply rounded border-none bg-white;
}
.react-datepicker__tab-loop .react-datepicker .react-datepicker__month-dropdown .react-datepicker__month-option,
.react-datepicker__tab-loop .react-datepicker .react-datepicker__year-dropdown .react-datepicker__year-option{
  @apply py-1 px-2 text-left;
}
.react-datepicker__tab-loop .react-datepicker .react-datepicker__month-dropdown 
.react-datepicker__month-option.react-datepicker__month-option--selected_month,
.react-datepicker__tab-loop .react-datepicker .react-datepicker__year-dropdown 
.react-datepicker__year-option.react-datepicker__year-option--selected_year{
  @apply bg-sky text-white font-semibold;
}
.react-datepicker__tab-loop .react-datepicker .react-datepicker__month-dropdown 
.react-datepicker__month-option.react-datepicker__month-option--selected_month span,
.react-datepicker__tab-loop .react-datepicker .react-datepicker__year-dropdown 
.react-datepicker__year-option.react-datepicker__year-option--selected_year span{
  display: none;
}
.react-datepicker__tab-loop .react-datepicker .react-datepicker__header .react-datepicker__month-dropdown-container,
.react-datepicker__tab-loop .react-datepicker .react-datepicker__header .react-datepicker__year-dropdown-container{
  @apply mr-4 ml-2
}
.react-datepicker__tab-loop .react-datepicker__month-container .react-datepicker__month .react-datepicker__day--selected,
.react-datepicker__tab-loop .react-datepicker__month-container .react-datepicker__month 
.react-datepicker__day--keyboard-selected{
  @apply rounded bg-sky;
}
.react-datepicker__tab-loop .react-datepicker .react-datepicker__year-dropdown .react-datepicker__year-option a{
  border-width: 1px;
  @apply border-sky block h-2 w-2 border-l-0 border-b-0 border-solid top-0 -rotate-45 mt-1.5;
}
.react-datepicker__tab-loop .react-datepicker .react-datepicker__year-dropdown 
.react-datepicker__year-option:last-child a{
  @apply rotate-135 mt-0 mb-1.5;
}

.accordian-panel-container .accordian-panel .grid-template-column{
  grid-template-columns: max-content auto;
}
.select-record-container{
  height: calc(100vh - 125px);
}

.has-error .rsw-editor{
  @apply border-pink-deep;
}
.rsw-editor .rsw-ce{
  @apply bg-white leading-tight;
}
.rsw-editor .rsw-ce a, .html-body a{
  @apply text-sky underline;
}
.rsw-editor .rsw-ce ol, .html-body ol{
  @apply list-decimal list-inside;
}
.rsw-editor .rsw-ce ul, .html-body ul{
  @apply list-disc list-inside;
}
.fixed-height .rsw-editor .rsw-ce{
  @apply max-h-15 overflow-auto;
}
.rsw-editor .rsw-toolbar button[title="Undo"],
.rsw-editor .rsw-toolbar button[title="Redo"],
.rsw-editor .rsw-toolbar button[title="HTML mode"],
.rsw-editor .rsw-toolbar button[title="Redo"] + .rsw-separator{
  @apply hidden;
}
.popup-editor iframe{
  @apply rounded;
}
.popup-editor .rsw-editor{
  width: 100%;
  height: calc(100vh - 30px);
}
.popup-editor .rsw-editor .rsw-ce{
  max-height: calc(100vh - 55px);
  overflow: auto;
}
.popup-editor img{
  max-height: calc(100vh - 10px);
}
.html-body > *:first-child{
  margin-top: 0;
  padding-top: 0;
}
.html-body ul li, .html-body ol li{
  @apply mb-3;
}
.html-body ul li:last-child, .html-body ol li:last-child{
  @apply mb-0;
}

@keyframes stripes {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 60px 30px;
  }
}
@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}

#drag-drop-file-area .upload-area,
#drag-drop-dicom-file .upload-area{
  @apply block cursor-pointer rounded mb-3 border-dashed border border-gray h-30 bg-gray-light;
}
#drag-drop-file-area.highlight .upload-area,
#drag-drop-dicom-file.highlight .upload-area{
  background-size: 30px 30px;
  animation: stripes 2s linear infinite;
  background-image: linear-gradient(
    -45deg, #FAFAFA 25%,
    transparent 25%,transparent 50%, 
    #FAFAFA 50%, #FAFAFA 75%, 
    transparent 75%,transparent
  );
}
.progress-ring-cont .progress-ring{
  transition: 0.35s stroke-dashoffset;
  transform-origin: 50% 50%;
  @apply -rotate-90;
}

.s-alert-wrapper .s-alert-box{
  z-index: 2147483647;
  @apply rounded py-2 pr-6 pl-4;
}
.s-alert-wrapper .s-alert-box .s-alert-box-inner{
  @apply font-medium;
}
.s-alert-wrapper .s-alert-box .s-alert-close{
  top: calc(50% - 10px);
  right: 6px;
}
.s-alert-wrapper .s-alert-box .s-alert-close::before,
.s-alert-wrapper .s-alert-box .s-alert-close::after{
  width: 1px;
  height: 80%;
}

.scroll-container--header{
  height: calc(100vh - 50px);
  overflow-y: auto;
  overflow-x: hidden;
}

#navbar{
  @apply ml-4;
}
#navbar #toggle{
  @apply hidden;
}
#navbar #toggle:checked + .hamburger .top {
  @apply -rotate-45;
  margin-top: 17px;
}
#navbar #toggle:checked + .hamburger .meat {
  @apply rotate-45 mt-0;
}
#navbar #toggle:checked + .hamburger .bottom {
  @apply scale-0;
}
#navbar .hamburger{
  @apply h-5 grid grid-rows-3 items-center z-10;
}
#navbar .hamburger div {
  @apply bg-white relative w-5 h-0.5 duration-200 transition-all ease-in-out;
}
#navbar .menu{
  @apply absolute top-10 opacity-0 bg-white rounded-bl rounded-br border-b border-card-shadow 
  duration-200 transition-opacity ease-in-out invisible;
}
#navbar .menu .menu-item{
  @apply block w-full px-3 py-2 text-black hover:bg-gray-light;
}
#navbar #toggle:checked ~ .menu {
  @apply opacity-100 visible duration-200 transition-opacity ease-in-out;
}
.pac-container{
  font-family: 'Inter', sans-serif;
}
.pac-container .pac-item:hover{
  @apply bg-list-bg;
}

ol.counter{
  counter-reset: item;
}
ol.counter > li{
  @apply block relative;
}
ol.counter > li:before {
  content: counters(item, ".") ". ";
  counter-increment: item;
  @apply left-0 absolute;
}
ol.counter > li.pl-6:before{
  @apply font-bold text-medium-l;
}

.dotted-background .custom-dashboard{
  height: calc(100vh - 105px);
}

/* ::-webkit-scrollbar{
  width: 6px; 
  height: 6px;
  border-radius: 5px;
  overflow: auto;
}
::-webkit-scrollbar-track{
  background: rgba(225, 218, 242, 0.5);
}
::-webkit-scrollbar-thumb{
  background: rgb(225, 218, 242);
} */

.report-block-container .report.page{
  page-break-before: always;
  background-color: white;
  position: relative;
  z-index: 2;
}
.report-block-container .page-header-space, 
.report-block-container .page-footer-space {
  height: 100px;
}
.report-block-container .page-header, .report-block-container .page-footer{
  position: fixed;
  width: 100%;
  z-index: 1;
}
.report-block-container .page-footer{
  bottom: 0;
  padding-bottom: 5px;
}
.report-block-container .page-header {
  top: 0;
}
@page {
  margin-top: 5mm;
  margin-bottom: 5mm;
}
@media print {
  thead {display: table-header-group;} 
  tfoot {display: table-footer-group;}
  body {margin: 0;}
}